import React from "react";

export interface IBullProps {}

export const Bull: React.FC<IBullProps> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35.068 24.545"
      width="100%"
      height="100%"
      stroke="currentColor"
      fill="currentColor"
      {...props}
    >
      <path d="M18.664 6.452s-1.93.193-4.632-1.158S6.989 3.268 5.541 3.17c0 0-.097-.965-1.737-1.447C2.164 1.24.718.952.235.18c-.483-.772-.242 1.157 1.399 1.785 1.64.627 1.93.65 1.93 1.266S2.551 4.523 2.358 5.053c-.193.53-.869 1.833-1.303 2.268C.621 7.755-.11 7.9.014 8.768c.124.869 1.282 1.544 2.15 1.447.869-.096 3.281.483 3.281 1.352 0 .868.579 3.28 2.895 5.21 2.316 1.93 2.015 2.689 2.017 4.283.001 1.288-.179 1.754-.828 2.569-.474.595-.272.916.162.916h2.075c.272 0 0-.772.192-1.11.193-.337.628-.193.387-1.4-.242-1.205-.242-2.46.192-3.328.435-.87 1.399-.82 2.509-.724 1.109.097 5.741.965 7.623-.917 0 0 .965 1.785 2.846 2.412 1.881.627 1.254 2.219 1.158 2.895-.096.676-1.168.916-1.158 1.689.006.482.29.482.772.482h1.592c.338 0 0-.868.242-1.013.241-.145.627-.386.627-1.496 0-1.11-.096-3.04-.531-3.522-.434-.482-.675-2.171-.241-2.991.434-.82 2.413-3.33 1.785-5.451-.627-2.123 1.022-1.426.956.053-.097 2.149-1.335 7.35 1.939 9.114 0 0 .772 1.688 2.412 1.206 0 0-.524-1.405-2.067-1.668 0 0-2.623-1.517-1.705-5.982.604-2.936.547-5.467-1.897-5.274 0 0-1.277-2.66-3.834-2.176-2.558.48-5.375 1.217-6.901 1.108z" />
    </svg>
  );
};
