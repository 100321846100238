import React from "react";

export interface IDiceProps {}

export const Dice: React.FC<IDiceProps> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 94.3 72.541"
      width="100%"
      height="100%"
      stroke="currentColor"
      fill="currentColor"
    >
      <path
        d="M93.726 36.646l-14.71 32.2a6.321 6.321 0 0 1-8.376 3.122l-32.2-14.71a6.322 6.322 0 0 1-3.124-8.377l14.711-32.2a6.32 6.32 0 0 1 8.376-3.123l32.201 14.71a6.322 6.322 0 0 1 3.122 8.378zM61.082 21.384a6.005 6.005 0 1 0-4.989 10.925 6.005 6.005 0 1 0 4.99-10.925zm5.935 15.917a6.007 6.007 0 1 0-4.99 10.926 6.007 6.007 0 0 0 4.99-10.926zm5.934 15.915a6.005 6.005 0 0 0-7.957 2.968 6.004 6.004 0 0 0 2.967 7.958 6.005 6.005 0 0 0 7.957-2.967 6.007 6.007 0 0 0-2.967-7.959zM30.456 49.9c-.93 2.037-.826 4.263.044 6.147l-6.543 3.052a6.322 6.322 0 0 1-8.4-3.058L.594 23.956a6.32 6.32 0 0 1 3.06-8.4L35.736.593a6.32 6.32 0 0 1 8.4 3.058l3.796 8.142c-.104.185-.23.351-.319.547zM19.328 21.5a6.005 6.005 0 1 0-10.885 5.075 6.005 6.005 0 1 0 10.885-5.076zM21.5 40.364a6.002 6.002 0 0 0-2.904 7.98 6.004 6.004 0 0 0 7.98 2.904A6.005 6.005 0 0 0 21.5 40.365zM41.1 11.346a6.006 6.006 0 1 0-10.886 5.077A6.006 6.006 0 0 0 41.1 11.346z"
        clip-rule="evenodd"
        fill-rule="evenodd"
      />
    </svg>
  );
};
