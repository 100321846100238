/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";

function useWindowSize() {
  const [size, setSize] = React.useState([
    window.innerWidth,
    window.innerHeight
  ]);

  React.useEffect(() => {
    const handleResize = () => setSize([window.innerWidth, window.innerHeight]);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return size;
}

function useCanvas(
  draw: (ctx: CanvasRenderingContext2D) => void,
  width?: number,
  height?: number
) {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  const windowSize = useWindowSize();

  React.useEffect(() => {
    canvasRef.current!.width = width || windowSize[0];
    canvasRef.current!.height = height || windowSize[1];
    const ctx = canvasRef.current!.getContext("2d")!;
    let animationFrameId = requestAnimationFrame(renderFrame);

    function renderFrame() {
      animationFrameId = requestAnimationFrame(renderFrame);
      ctx.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
      draw(ctx);
    }

    return () => cancelAnimationFrame(animationFrameId);
  }, [draw, height, width]);

  return canvasRef;
}

export interface IRainProps {
  drop: string;
}

class Drop {
  x: number;
  y: number;
  image: HTMLImageElement;
  speed: number;
  width: number;
  height: number;
  rotation: number;
  constructor(
    image: HTMLImageElement,
    x = Math.floor(Math.random() * window.innerWidth),
    y = Math.floor(Math.random() * window.innerHeight)
  ) {
    this.x = x;
    this.y = y;
    this.image = image;
    this.speed = Math.random() * 3 + 1;
    this.width = image.width * 0.1;
    this.height = image.height * 0.1;
    this.rotation = Math.random() * 360;
  }
  draw(context: CanvasRenderingContext2D) {
    const center = [this.x + this.width / 2, this.y + this.height / 2];
    context.save();
    context.translate(center[0], center[1]);
    context.rotate((Math.PI / 180) * this.rotation);
    context.translate(-center[0], -center[1]);
    context.drawImage(this.image, this.x, this.y, this.width, this.height);
    context.restore();
  }
  animate() {
    this.y += this.speed;
    this.rotation += 1;
    if (this.y > window.innerHeight) {
      this.y = 0 - this.height;
    }
  }
}

function useLoadImage(url: string): [boolean, HTMLImageElement] {
  const [loaded, setLoaded] = React.useState(false);
  const image = new Image();
  image.src = url;
  image.onload = () => {
    setLoaded(true);
  };
  return [loaded, image];
}

export const Rain: React.FC<IRainProps> = ({ drop }) => {
  const [loaded, image] = useLoadImage(drop);
  const drops: Drop[] = [];
  for (let i = 0; i < window.innerWidth / 16; i++) {
    drops[i] = new Drop(
      image
      // (i % 10) * (window.innerWidth / 10),
      // Math.floor(i / 10) * (window.innerHeight / 5)
    );
  }
  const canvasRef = useCanvas(ctx => {
    for (let i = 0; i < drops.length; i++) {
      drops[i].draw(ctx);
      drops[i].animate();
    }
  });

  return (
    <canvas
      ref={canvasRef}
      css={css`
        position: fixed;
        z-index: 1;
        pointer-events: none;
      `}
    />
  );
};
