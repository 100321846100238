/** @jsx jsx */
import React from "react";
import { css, jsx, Global } from "@emotion/core";
import { Sidebar } from "./Sidebar";
import { Footer } from "./Footer";
import { GangLayout } from "./layouts/Gang";
import { Rain } from "./Rain";
import { OptionLayout } from "./layouts/Option";
import useKonamiCode from "./hooks/konami";
import { Button } from "./Button";

interface ITheme {
  primary: string;
}

interface IThemeContext {
  theme: ITheme;
  setTheme: React.Dispatch<any>;
}

export const themes = {
  bull: {
    primary: "green"
  },
  bear: {
    primary: "red"
  }
};

const themeContext: IThemeContext = {
  theme: themes.bull,
  setTheme: () => {}
};

export const ThemeContext = React.createContext(themeContext);

const GlobalStyle = css`
  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html,
  body {
    font-size: 10px;
    background: #060608;
    height: 100%;
  }

  h1 {
    margin-bottom: 3rem;
    font-family: Ubuntu;
    font-size: 6.103515625em;
    line-height: 9rem;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0em;
  }

  h2 {
    margin-bottom: 3rem;
    font-family: Ubuntu;
    font-size: 4.8828125em;
    line-height: 6rem;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0em;
  }

  h3 {
    margin-bottom: 3rem;
    font-family: Ubuntu;
    font-size: 3.90625em;
    line-height: 6rem;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0em;
  }

  h4 {
    margin-bottom: 3rem;
    font-family: Ubuntu;
    font-size: 3.125em;
    line-height: 6rem;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0em;
  }

  h5 {
    margin-bottom: 3rem;
    font-family: Ubuntu;
    font-size: 2.5em;
    line-height: 3rem;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0em;
  }

  h6 {
    margin-bottom: 3rem;
    font-family: Ubuntu;
    font-size: 2em;
    line-height: 3rem;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0em;
  }

  p {
    margin-bottom: 3rem;
    font-family: Lora;
    font-size: 1.6em;
    line-height: 3rem;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0em;
  }

  @media screen and (max-width: 720px) {
    h1 {
      margin-bottom: 2.3rem;
      font-family: Ubuntu;
      font-size: 3.5831807999999996em;
      line-height: 4.6rem;
      font-weight: 500;
      font-style: normal;
      letter-spacing: 0em;
    }
    h2 {
      margin-bottom: 2.3rem;
      font-family: Ubuntu;
      font-size: 2.9859839999999997em;
      line-height: 4.6rem;
      font-weight: 500;
      font-style: normal;
      letter-spacing: 0em;
    }
    h3 {
      margin-bottom: 2.3rem;
      font-family: Ubuntu;
      font-size: 2.48832em;
      line-height: 4.6rem;
      font-weight: 500;
      font-style: normal;
      letter-spacing: 0em;
    }
    h4 {
      margin-bottom: 2.3rem;
      font-family: Ubuntu;
      font-size: 2.0736em;
      line-height: 2.3rem;
      font-weight: 500;
      font-style: normal;
      letter-spacing: 0em;
    }
    h5 {
      margin-bottom: 2.3rem;
      font-family: Ubuntu;
      font-size: 1.728em;
      line-height: 2.3rem;
      font-weight: 500;
      font-style: normal;
      letter-spacing: 0em;
    }
    h6 {
      margin-bottom: 2.3rem;
      font-family: Ubuntu;
      font-size: 1.44em;
      line-height: 2.3rem;
      font-weight: 500;
      font-style: normal;
      letter-spacing: 0em;
    }
    p {
      margin-bottom: 2.3rem;
      font-family: Lora;
      font-size: 1.2em;
      line-height: 2.3rem;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0em;
    }
  }
`;

const App: React.FC = () => {
  const [theme, setTheme] = React.useState(themes.bull);
  const [konami, setKonami] = useKonamiCode();
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <Global styles={GlobalStyle} />
      {konami && <Rain drop="tendie.png" />}
      <div
        css={css`
          display: grid;
          grid-template-columns: auto 1fr;
          grid-template-rows: 100% 100%;
          height: 100%;
        `}
      >
        <Sidebar>
          <Button
            icon="dice"
            onClick={() => {
              window.location.reload();
            }}
          />
        </Sidebar>
        {/* <GangLayout /> */}
        <div
          css={css`
            height: 100vh;
            overflow-y: scroll;
          `}
        >
          <OptionLayout />
        </div>
        {/* <Footer /> */}
      </div>
    </ThemeContext.Provider>
  );
};

export default App;
