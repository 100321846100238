/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { ThemeContext } from "./App";
import { Bull } from "./icons/Bull";
import { Bear } from "./icons/Bear";
import { Dice } from "./icons/Dice";

export interface IButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  icon?: "bull" | "bear" | "dice";
  text?: string;
}

const icons = {
  bull: <Bull />,
  bear: <Bear />,
  dice: <Dice />
};

export const Button: React.FC<IButtonProps> = ({
  children,
  className,
  icon,
  text,
  ...props
}) => {
  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <div
          className={className}
          css={css`
            background: none;
            color: white;
            display: inline-block;
            padding: 4px;
            cursor: pointer;
          `}
          {...props}
        >
          {icon && icons[icon]}
          {text}
          {children}
        </div>
      )}
    </ThemeContext.Consumer>
  );
};
