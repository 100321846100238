/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { SingularOption } from "../SingularOption";

export interface IOptionLayoutProps {}

interface OptionResponse {
  stock: Stock;
  options: Option[];
}

export interface Option {
  symbol: string;
  description: string;
  exch: string;
  type: string;
  last?: number;
  change?: number;
  volume: number;
  open?: number;
  high?: number;
  low?: number;
  close?: any;
  bid: number;
  ask: number;
  underlying: string;
  strike: number;
  change_percentage?: number;
  average_volume: number;
  last_volume: number;
  trade_date: number;
  prevclose?: number;
  week_52_high: number;
  week_52_low: number;
  bidsize: number;
  bidexch: string;
  bid_date: number;
  asksize: number;
  askexch: string;
  ask_date: number;
  open_interest: number;
  contract_size: number;
  expiration_date: string;
  expiration_type: string;
  option_type: string;
  root_symbol: string;
}

interface Stock {
  symbol: string;
  description: string;
  exch: string;
  type: string;
  last: number;
  change: number;
  volume: number;
  open: number;
  high: number;
  low: number;
  close?: any;
  bid: number;
  ask: number;
  change_percentage: number;
  average_volume: number;
  last_volume: number;
  trade_date: number;
  prevclose: number;
  week_52_high: number;
  week_52_low: number;
  bidsize: number;
  bidexch: string;
  bid_date: number;
  asksize: number;
  askexch: string;
  ask_date: number;
  root_symbols: string;
}

const OptionGridStyle = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 400px));
  grid-gap: 24px;
`;

export const OptionLayout: React.FC<IOptionLayoutProps> = () => {
  const [optionData, setOptionData] = React.useState<OptionResponse>();
  React.useEffect(() => {
    async function fetchOptionData() {
      const response = await fetch("/api/options/random");
      const data = await response.json();
      setOptionData(data);
    }
    fetchOptionData();
  }, []);
  if (optionData) {
    const calls = optionData.options
      .filter(option => {
        return option.option_type === "call";
      })

      .sort((a, b) => {
        return b.strike - a.strike;
      });
    return (
      <div
        css={css`
          margin: 0 24px;
        `}
      >
        <h2
          css={css`
            color: white;
            margin-bottom: 0;
          `}
        >
          {optionData.stock.description} ({optionData.stock.symbol})
        </h2>
        <h3
          css={css`
            color: ${optionData.stock.change > 0 ? "green" : "red"};
          `}
        >
          ${optionData.stock.last.toFixed(2)}
          {" | "}
          {optionData.stock.change_percentage}%
        </h3>
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://robinhood.com/stocks/${optionData.stock.symbol}`}
          >
            <img src="robinhood.svg" alt="" />
            Robinhood
          </a>
        </div>
        <div css={OptionGridStyle}>
          {calls
            .filter(option => {
              return option.strike > optionData.stock.last;
            })
            .reverse()
            .slice(0, 3)
            .map((option, index) => (
              <SingularOption option={option} risk={index + 1} css={css``} />
            ))}
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            color: white;
            hr {
              flex: 1;
            }
            h4 {
              margin: 0;
              padding: 0 8px;
              line-height: 6rem;
            }
          `}
        >
          <hr />
          <h4>Share Price: ${optionData.stock.last.toFixed(2)}</h4>
          <hr />
        </div>
        <div css={OptionGridStyle}>
          {calls
            .filter(option => {
              return option.strike < optionData.stock.last;
            })
            .slice(0, 3)
            .map(option => (
              <SingularOption option={option} />
            ))}
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
