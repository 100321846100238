/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { ThemeContext } from "./App";

export interface IHeaderProps {}

export const Sidebar: React.FC<IHeaderProps> = ({ children }) => {
  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <aside
          css={css`
            height: 100%;
            width: 50px;
            background: #282a30;
            display: grid;
            grid-template-columns: auto;
            grid-auto-rows: min-content;
            grid-row-gap: 16px;
            padding: 4px;
          `}
        >
          {children}
        </aside>
      )}
    </ThemeContext.Consumer>
  );
};
