/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { Option } from "./layouts/Option";
import * as dateFns from "date-fns";

export interface ISingularOptionProps {
  option: Option;
  className?: string;
  risk?: number;
}

export const SingularOption: React.FC<ISingularOptionProps> = ({
  option,
  className,
  risk
}) => {
  return (
    <div
      className={className}
      key={option.symbol}
      css={css`
        background: #25262c;
        padding: 0 16px 0 0;
        color: #b8bac3;

        border-left: 8px solid
          ${risk === 1
            ? "#30a135"
            : risk === 2
            ? "#f0ba32"
            : risk === 3
            ? "#df2525"
            : "#2b2a31"};
        h4 {
          span {
            color: white;
            font-size: 1.1em;
            text-transform: uppercase;
          }
        }
      `}
    >
      <table
        css={css`
          padding: 24px 8px;
          font-size: 1.6em;
          font-family: Ubuntu;
          td {
            padding-top: 24px;
            width: 100%;
            padding-left: 24px;
          }
          tr:last-of-type {
            td {
              padding-bottom: 24px;
            }
          }
          th {
            background: #1d1d20;
            text-align: left;
            padding: 0 24px 0 12px;
            white-space: nowrap;
          }
        `}
      >
        <tr>
          <th>Type</th>
          <td>{option.option_type === "call" ? "Call" : "Put"}</td>
        </tr>
        <tr>
          <th>Strike</th>
          <td>${option.strike.toFixed(2)}</td>
        </tr>
        <tr>
          <th>Expiration</th>
          <td>{option.expiration_date}</td>
        </tr>
        <tr>
          <th>Cost</th>
          <td>${option.last ? option.last.toFixed(2) : 0.0}</td>
        </tr>
        <tr>
          <th>Bid</th>
          <td>
            ${option.bid.toFixed(2)} x {option.bidsize}
          </td>
        </tr>
        <tr>
          <th>Ask</th>
          <td>
            ${option.ask.toFixed(2)} x {option.asksize}
          </td>
        </tr>
        <tr>
          <th>Open Interest</th>
          <td>{option.open_interest}</td>
        </tr>
        <tr>
          <th>Risk</th>
          <td>
            {risk === 1
              ? "Low"
              : risk === 2
              ? "Medium"
              : risk === 3
              ? "High"
              : "N/A"}
          </td>
        </tr>
      </table>
    </div>
  );
};
